import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function relateCompanyList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'company_id', label: '公司ID' },
    { key: 'company_name', label: '公司名称' },
    { key: 'status', label: '状态' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" company_id ")
  const isSortDirDesc = ref(true)
  const searchKey = ref("")

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit,searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['relateCompanyList/getCondition']}
    store
        .dispatch('relateCompanyList/search', {
          company_name: searchKey.value,
          company_type: config.company_type,

          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          console.log("dddddddddd")
          console.log(response.data.data)
          const list = response.data.data.ext.list
          listTotals.value = response.data.data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '公司列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchKey,
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
