<template>
  <b-card>
    <b-form id="storeCasgOutEditForm" class="edit-form mt-2">
      <b-row>

        <b-col
            cols="12"
            md="1"
        >
          <b-form-group label="录入方式"></b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="5"
        >
          <b-form-group label="" style="margin-left: 55px">
            <b-form-radio-group>
              <b-form-radio
                  v-model="input_type"
                  plain
                  name="some-radios3"
                  value="1"
                  style="width: 235px"
                  :disabled="true"
              >
                上传解析
              </b-form-radio>
<!--              <b-form-radio
                  v-model="input_type"
                  plain
                  name="some-radios3"
                  value="2"
                  :disabled="true"
              >
                接口查询
              </b-form-radio> TODO-->
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label=""></b-form-group>
        </b-col>
        <b-col cols="12" md="1">
          <b-form-group label=""></b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="" style="margin-top: -15px;margin-left:55px;color: #82868b">
            <span>电子档直接上传识别，专普票均可</span>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="" style="margin-top: -15px;margin-left:-52px;color: #82868b">
<!--            <span>仅使用于增值税专用发票</span> TODO-->
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label=""></b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="1"
        >
          <b-form-group label="发票文件"></b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >

          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label="发票形式"
              label-for="invoice_material"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="invoice_material"
                :options="getCodeOptions('invoice_material')"
                :clearable="true"
                v-model = "invoice_material"
                class="select-size-sm"
                placeholder="请选择发票形式"
                :reduce="option => option.value"
                :disabled="readonlyFlag"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >

          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label="发票类型"
              label-for="invoice_type"
              label-size="sm"
              style="margin-left: -35px"
              class="mb-1 required"
          >
            <v-select
                id="invoice_type"
                :options="getCodeOptions('invoice_type')"
                :clearable="true"
                v-model="invoice_type"
                class="select-size-sm"
                placeholder="请选择发票类型"
                :reduce="option => option.value"
                :disabled="readonlyFlag"
                @input="changeInvoiceType()"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group label=""></b-form-group>
        </b-col>
        <b-col md="12" >
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="purhaseInvoice.loaded":theme="'files'"
                               :attachment_id="'attachments'"
                               :id="purhaseInvoice.attachments"
                               :object_type="'purhaseInvoice'"
                               :object_id="purhaseInvoice.invoice_id"
                               @change="onUploaded"
                               :readonly="readonlyAttFlag"
                               style="margin-left: 55px;width: 500px;float: left"
            />
            <div style="margin-top: 17px"><span style="margin-left: 10px">支持pdf/jpg/jpeg/png</span></div>

          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="dataList.length>0">
        <span>票面信息</span>
      </b-row>
      <b-row v-if="dataList.length>0">
        <span style="color: #cb2027">根据财务要求：普通发票税额按0处理</span>
        <b-table-simple striped hover bordered fixed v-for="(data,index) in dataList" :key="index" style="margin-bottom: 20px">
          <b-tbody>
            <b-tr>
              <b-td colspan="4" style="text-align: center">发票代码</b-td>
              <b-td colspan="3" style="text-align: center">发票号码</b-td>
              <b-td colspan="3" style="text-align: center">开票日期</b-td>
              <b-td colspan="6" style="text-align: center">校验码</b-td>
              <b-td colspan="4" style="text-align: center">发票类型</b-td>
              <b-td colspan="4" style="text-align: center">发票形式</b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="4" style="text-align: center">
                <b-form-input
                  size="sm"
                  v-model="data.invoice_code"
                  :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="3" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.invoice_no"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="3" style="text-align: center">
                <flat-pickr
                  v-model="data.make_date_val"
                  class="form-control"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  :disabled="readonlyFlag"
                />
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.check_no"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="4" style="text-align: center">
<!--                <b-form-input
                    size="sm"
                    v-model="data.invoice_type_str"
                    :readonly="readonlyFlag"
                />-->
              </b-td>
              <b-td colspan="4" style="text-align: center">
<!--                <b-form-input
                    size="sm"
                    v-model="data.invoice_material_str"
                    :readonly="readonlyFlag"
                />-->
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="6" style="text-align: center">购买方名称</b-td>
              <b-td colspan="6" style="text-align: center">购买方纳税人识别码</b-td>
              <b-td colspan="6" style="text-align: center">购买方地址、电话</b-td>
              <b-td colspan="6" style="text-align: center">购买方开户行及账号</b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    style="float: left;width: 80%"
                    v-model="data.buyer_name"
                    @change="resetSbcCompanyId(data,data.buyer_name,'1')"
                    :readonly="readonlyFlag"
                />
                <span :id="`remark-row-`+index+`${data.buyer_sbc_company_id}`">
                  {{data.buyer_sbc_company_id}}
                </span>
                <b-tooltip
                    :target="`remark-row-`+index+`${data.buyer_sbc_company_id}`"
                    placement="top"
                    v-if="data.buyer_sbc_company_name"
                >
                  {{ data.buyer_sbc_company_name }}
                </b-tooltip>
                <span v-if="!readonlyFlag" class="align-middle ml-50" v-b-modal.modal-company @click="relateCompany(data.invoice_no,'buyer')">
                  <feather-icon icon="CommandIcon" style="margin-top: 7px"/>
                </span>
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.buyer_idno"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.buyer_addinfo"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.buyer_bankinfo"
                    :readonly="readonlyFlag"
                />
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="6" style="text-align: center">销售方方名称</b-td>
              <b-td colspan="6" style="text-align: center">销售方纳税人识别码</b-td>
              <b-td colspan="6" style="text-align: center">销售方地址、电话</b-td>
              <b-td colspan="6" style="text-align: center">销售方开户行及账号</b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.sell_name"
                    @change="resetSbcCompanyId(data,data.sell_name,'2')"
                    :readonly="readonlyFlag"
                    style="float: left;width: 80%"
                />
                <span :id="`remark-row-`+index+`${data.sell_sbc_company_id}`">
                  {{data.sell_sbc_company_id}}
                </span>
                <b-tooltip
                    :target="`remark-row-`+index+`${data.sell_sbc_company_id}`"
                    placement="top"
                    v-if="data.sell_sbc_company_name"
                >
                  {{ data.sell_sbc_company_name }}
                </b-tooltip>
                <span v-if="!readonlyFlag" class="align-middle ml-50" v-b-modal.modal-company @click="relateCompany(data.invoice_no,'seller')">
                  <feather-icon icon="CommandIcon" style="margin-top: 7px"/>
                </span>
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.sell_idno"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.sell_addinfo"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="data.sell_bankinfo"
                    :readonly="readonlyFlag"
                />
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="6" style="text-align: center">货品或应税劳务、服务名称</b-td>
              <b-td colspan="2" style="text-align: center">规格型号</b-td>
              <b-td colspan="2" style="text-align: center">单位</b-td>
              <b-td colspan="2" style="text-align: center">数量</b-td>
              <b-td colspan="3" style="text-align: center">单价</b-td>
              <b-td colspan="3" style="text-align: center">金额</b-td>
              <b-td colspan="3" style="text-align: center">税率</b-td>
              <b-td colspan="3" style="text-align: center">税额</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr v-for="(detail,indexD) in data.details" :key="indexD">
              <b-td colspan="6" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="detail.service_name"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="2" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="detail.specification"
                    :readonly="readonlyFlag"
                />
              </b-td>
              <b-td colspan="2" style="text-align: center">
                <b-form-input
                    size="sm"
                    v-model="detail.service_unit"
                    :readonly="readonlyFlag"
                /></b-td>
              <b-td colspan="2" style="text-align: center">
                <b-form-input
                    size="sm"
                    type="number"
                    @change="checkQty(detail)"
                    v-model="detail.service_qty"
                    :readonly="readonlyFlag"
                /></b-td>
              <b-td colspan="3" style="text-align: center">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="detail.price"
                    :readonly="readonlyFlag"
                /></b-td>
              <b-td colspan="3" style="text-align: center">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="detail.amount"
                    :readonly="readonlyFlag"
                    @change="changeAmount(index)"
                /></b-td>
              <b-td colspan="3" style="text-align: center">
                <b-form-input
                    style="width: 90%;float: left"
                    size="sm"
                    type="number"
                    v-model="detail.rate"
                    :readonly="readonlyFlag"
                />%</b-td>
              <b-td colspan="3" style="text-align: center">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="detail.tax_amount"
                    :readonly="readonlyFlag"
                    @change="changeAmount(index)"
                /></b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="24">
                合计: <b>{{data.total}}</b>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              v-if="!readonlyFlag"
              variant="outline-success"
              class="mr-1"
              @click="save(2)"
          >
            确认发票信息无误，提交
          </b-button>
          <b-button
              v-if="!readonlyFlag"
              variant="outline-success"
              class="mr-1"
              @click="save(1)"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="()=>{$router.go(-1)}"
          >
            取消
          </b-button>

        </b-col>
      </b-row>
    </b-form>

    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        ref="loadingModal"
    >
      <p class="my-4">正在处理，请勿关闭浏览器!</p>
    </b-modal>
    <b-modal
      v-if="showCompany"
      id="modal-company"
      ok-only
      ok-title="确认"
      @ok="onSelectCompany"
      cancel-title="取消"
      centered
      size="lg"
      title="选择公司"
      ref="singleModal"
  >
    <relate-company-list
        ref="relateCompanySelect"
        :company_type="company_type"
    >
    </relate-company-list>
  </b-modal>
  </b-card>
</template>


<script>

import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {computed, watch, onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
// Notification
import {useToast} from 'vue-toastification/composition'
import {getCodeOptions, getCode,getCodeLabel, getCodeColor, avatarText, isEmpty, toDate} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import purchaseInvoiceListStore from "@/views/apps/purchaseinvoice/purchaseInvoiceListStore";
import RelateCompanyList from "@/views/apps/purchaseinvoice/RelateCompanyList.vue";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BFormCheckbox,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,
    RelateCompanyList,
    // StoreSelect,
  },
  watch: {
    input_type:'changeInputType',
  },
  data() {
    return {
      purhaseInvoice:ref({}),
      input_type: '1',//TODO 默认undefined
      uploadFlag: true,//TODO 默认false
      readonlyFlag: false,
      readonlyAttFlag: false,
      invoice_id: 0,
      invoice_material: '',
      invoice_type: '',
      dataList:[],
      showCompany:false,
      company_type:null,
      invoiceNo:'',
      type:'',
    }
  },
  methods: {
    changeInvoiceType(){
      if(this.dataList&&this.dataList.length>0&&this.invoice_type&&this.invoice_type == 1){
        for(let i=0;i<this.dataList.length;i++){
          let total = 0;
          for(let j=0;j<this.dataList[i].details.length;j++){
            this.dataList[i].details[j].tax_amount = 0
            if(!isEmpty(this.dataList[i].details[j].amount)){
              total =this.accAdd(total,this.dataList[i].details[j].amount)
            }
          }
          this.dataList[i].amount = total
          this.dataList[i].tax_amount = 0
          this.dataList[i].total = total
        }
      }
    },
    changeAmount(index){
      let details = this.dataList[index].details;
      let total = 0;
      let amount = 0;
      let taxAmount = 0;
      for(let i=0;i<details.length;i++){
        if(!isEmpty(details[i].amount)){
          total =this.accAdd(total,details[i].amount)
          amount =this.accAdd(amount,details[i].amount)
        }
        if(!isEmpty(details[i].tax_amount)){
          total =this.accAdd(total,details[i].tax_amount)
          taxAmount =this.accAdd(taxAmount,details[i].tax_amount)
        }
      }
      this.dataList[index].amount = amount
      this.dataList[index].tax_amount = taxAmount
      this.dataList[index].total = total
      this.$forceUpdate()
    },
    accAdd(arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    },
    changeInputType(){
      if(this.input_type == '1'){
        this.uploadFlag = true
      }else{
        this.uploadFlag = false
      }
    },
    relateCompany(invoiceNo,type){
      this.invoiceNo = invoiceNo
      this.type = type
      if(type =='buyer'){
        this.company_type = 2
      }else{
        this.company_type = 3
      }
      this.showCompany=true;
    },
    resetSbcCompanyId(item,name,type){
      let obj = {}
      obj.invoice_company_name = name
      obj.type_id = type
      store.dispatch('purchaseInvoiceEdit/searchCompanyRelation', obj).then(res => {
        if (res.data.code==0){
          let data = res.data.data
          if(data && data.sbc_company_id){
            if(type == '1'){
              item.buyer_sbc_company_id = data.sbc_company_id
              item.buyer_sbc_company_name = data.ext.sbc_company_name
              this.$forceUpdate()
            }else if(type == '2'){
              item.sell_sbc_company_id = data.sbc_company_id
              item.sell_sbc_company_name = data.ext.sbc_company_name
              this.$forceUpdate()
            }
          }else{
            if(type == '1'){
              item.buyer_sbc_company_id = ''
              item.buyer_sbc_company_name = ''
              this.$forceUpdate()
            }else if(type == '2'){
              item.sell_sbc_company_id = ''
              item.sell_sbc_company_name = ''
              this.$forceUpdate()
            }
          }
        }else {
          alert(res.data.data);
        }
      })
    },
    onSelectCompany(){
      let data = this.$refs.relateCompanySelect.getSelected()[0]
      for(let i=0;i<this.dataList.length;i++){
        if(this.dataList[i]["invoice_no"] == this.invoiceNo){
          if(this.type == 'buyer'){
            this.dataList[i]["buyer_sbc_company_id"] = data.company_id
            this.dataList[i]["buyer_sbc_company_name"] = data.company_name
            this.$forceUpdate()
          }else{
            this.dataList[i]["sell_sbc_company_id"] = data.company_id
            this.dataList[i]["sell_sbc_company_name"] = data.company_name
            this.$forceUpdate()
          }
        }
      }
    },
    save(status){
      if(isEmpty(this.invoice_type)){
        alert("请选择发票类型")
        return
      }
      if(isEmpty(this.invoice_material)){
        alert("请选择发票形式")
        return
      }
      if(!this.dataList.length>0){
        alert("请先上传发票")
        return
      }
      for(let i=0;i<this.dataList.length;i++){
        if(isEmpty(this.dataList[i].invoice_code)){
          alert("发票"+(i+1)+"发票代码不可以为空")
          return;
        }
        if(isEmpty(this.dataList[i].invoice_no)){
          alert("发票"+(i+1)+"发票号码不可以为空")
          return;
        }
        if(isEmpty(this.dataList[i].make_date_val)){
          alert("发票"+(i+1)+"开票日期不可以为空")
          return;
        }
        /*if(isEmpty(this.dataList[i].check_no)){
          alert("发票"+(i+1)+"校验码不可以为空")
          return;
        }*/
        if(isEmpty(this.dataList[i].buyer_name)){
          alert("发票"+(i+1)+"购买方名称不可以为空")
          return;
        }
        if(isEmpty(this.dataList[i].buyer_idno)){
          alert("发票"+(i+1)+"购买方纳税人识别码不可以为空")
          return;
        }
        if(isEmpty(this.dataList[i].sell_name)){
          alert("发票"+(i+1)+"销售方方名称不可以为空")
          return;
        }
        if(isEmpty(this.dataList[i].sell_idno)){
          alert("发票"+(i+1)+"销售方纳税人识别码不可以为空")
          return;
        }
        if(!this.dataList[i].details||this.dataList[i].details ==0){
          alert("发票"+(i+1)+"无明细")
          return;
        }else{
          let details = this.dataList[i].details;
          for(let j=0;j<details.length;j++){
            if(isEmpty(details[j].service_name)){
              alert("发票"+(i+1)+"明细"+(j+1)+"货品或应税劳务、服务名称不可以为空")
              return;
            }
            if(isEmpty(details[j].amount)){
              alert("发票"+(i+1)+"明细"+(j+1)+"金额不可以为空")
              return;
            }
            if(isEmpty(details[j].rate)){
              alert("发票"+(i+1)+"明细"+(j+1)+"税率不可以为空")
              return;
            }
            if(isEmpty(details[j].tax_amount)&&details[j].tax_amount !='0'){
              alert("发票"+(i+1)+"明细"+(j+1)+"税额不可以为空")
              return;
            }
          }
        }
      }
      let arr = [];
      for(let i=0;i<this.dataList.length;i++){
        let buyer_sbc_company_id = this.dataList[i]["buyer_sbc_company_id"];
        let sell_sbc_company_id = this.dataList[i]["sell_sbc_company_id"];
        if(buyer_sbc_company_id==undefined||buyer_sbc_company_id==null||buyer_sbc_company_id==''){
          arr.push("发票号"+this.dataList[i]["invoice_no"]+"购买方未关联SBC公司")
        }
        if(sell_sbc_company_id==undefined||sell_sbc_company_id==null||sell_sbc_company_id==''){
          arr.push("发票号"+this.dataList[i]["invoice_no"]+"销售方未关联SBC公司")
        }
      }
      if(arr.length>0){
        alert(arr.join(","));
        return;
      }

      let obj = {}
      obj.status = status
      obj.invoice_material = this.invoice_material
      obj.invoice_type = this.invoice_type
      obj.invoiceJsonStr = JSON.stringify(this.dataList)
      this.$refs['loadingModal'].show()
      store.dispatch('purchaseInvoiceEdit/save', obj).then(res => {
        this.$refs['loadingModal'].hide()
        if (res.data.code==0){
          this.$router.push({ name: 'apps-purchase-invoice-list'});
        }else {
          alert(res.data.data);
        }
      })
    },
    checkQty(item){
      if(!item.num){
        // alert('请填写数量!')
        return
      }
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.num)) {
        item.num = null;
        alert('数量必须是正整数!')
      }
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseInvoiceEdit')) store.registerModule('purchaseInvoiceEdit', purchaseInvoiceListStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseInvoiceEdit')) store.unregisterModule('purchaseInvoiceEdit')
    })
    const toast = useToast()
    //文件上传、删除
    const onUploaded = function (id, attachment, result,files) {
      console.log("result")
      console.log(result)
      if(result&&result.length>0){
        /*let attarr = []
        for(let i = 0; i < attachment.length; i++) {
          attarr.push(attachment[i]["file_name"])
        }
        for(let i = 0; i < files.length; i++) {
          if(!attarr.includes(files[i]["name"])){
            files.splice(i, 1);
          }
        }*/
        const attachment_ids = result.split(",")
        //删除文件
        if(attachment_ids.length<this.dataList.length){
          const newDataList = [];
          for(let i = 0; i < this.dataList.length; i++) {
            if(attachment_ids.includes(this.dataList[i].attachments)){
              newDataList.push(this.dataList[i])
            }
          }
          this.dataList = newDataList
          this.$forceUpdate()
        }else{
          //新增发票文件
          // const fileArr = [];
          const ids = [];
          const params = new FormData()
          // for(let i = 0; i < attachment_ids.length-this.dataList.length; i++) {
          let count = 0
          for(let i = this.dataList.length; i < attachment_ids.length; i++) {
            //调接口files
            ids.push(attachment_ids[i])
            params.append('file' + i,files[count])
            count++;
          }
          params.append('ids',ids.join(","))
          this.$refs['loadingModal'].show()
          store.dispatch('purchaseInvoiceEdit/analyseInvoice', params).then(res => {

            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              const list = res.data.data
              console.log("123")
              console.log(list)
              for(let i=0;i<list.length;i++){
                //普通发票，税额为0
                if(this.invoice_type&&this.invoice_type == 1){
                  let total = 0;
                  for(let j=0;j<list[i].details.length;j++){
                    list[i].details[j].tax_amount = 0
                    if(!isEmpty(list[i].details[j].amount)){
                      total =this.accAdd(total,list[i].details[j].amount)
                    }
                  }
                  list[i].amount = total
                  list[i].tax_amount = 0
                  list[i].total = total
                }
                this.dataList.push(list[i])
              }
            }
          })
        }
      }else{
        this.dataList = [];
      }
    }

    const edit = function () {
      store.dispatch('purchaseInvoiceEdit/initEditPage', {id:this.invoice_id}).then(res => {
        this.purhaseInvoice = res.data.data
        if(this.invoice_id != 0){
          this.readonlyAttFlag = true
          this.invoice_type = String(this.purhaseInvoice.invoice_type)
          this.invoice_material = String(this.purhaseInvoice.invoice_material)
        }
        if(this.purhaseInvoice.invoice_id){
          this.dataList.push(this.purhaseInvoice)
        }
      })
    }

    return {
      toast,
      edit,
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      toDate,
      isEmpty,
      getCodeColor,
      onUploaded,

    }
  },
  created() {
    this.invoice_id = this.$route.query.id || 0;
    let flag = this.$route.query.readonlyFlag || false;
    this.readonlyFlag = JSON.parse(flag)
    this.edit();
  },
}
</script>
